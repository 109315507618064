// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvironmentEnum, EnvironmentModel } from '@mlc/types';

export const environment: EnvironmentModel = {
  production: false,
  baseUrl: 'https://mlc-strapi-asset-manager-mhtfux2ooa-od.a.run.app/api',
  SENTRY_ENV: EnvironmentEnum.STAGING,
  SENTRY_LINK: 'https://679b7cba5ed0b4cc4ef3cb4077c1d7f8@o4507406465171456.ingest.de.sentry.io/4507406466547792',
  COIN_MARKET_CAP_URL: 'https://coinmarketcap.com/fr/currencies'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
