import { Injectable } from '@angular/core';
import { ReplaySubject, lastValueFrom, take } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as SentryAngular from '@sentry/angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Client } from '../../models';
import { toSignal } from "@angular/core/rxjs-interop";
import { ta } from "date-fns/locale";

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UserService {
  public onlineUser$: ReplaySubject<Client | undefined> = new ReplaySubject<Client | undefined>(0);
  private jwt?: string;

  constructor (
    private storage: StorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient
  ) {
    this.storage.get('user').then((userJSON: string) => {
      this.loadUser();
    });
  }

  public async loadUser () {
    const token = await this.storage.get('token');
    if (token) {
      this.jwt = token;
      try {
        const userData: Client = await lastValueFrom(this.onlineUser);
        this.onlineUser$.next(userData);
        this.saveLocalUser(userData);
      } catch (e: any) {
        return
      }
    } else {
      this.onlineUser$.next(undefined);
    }
  }

  get () {
    return this.onlineUser$.asObservable();
  }

  get token () {
    return this.jwt;
  }

  private get onlineUser () {
    return this.httpClient.get<Client>(`${environment.baseUrl}/users/me`);
  }

  authenticate (jwt: string, user: Client) {
    this.storage.set('token', jwt);
    this.jwt = jwt;
    this.onlineUser$.next(user);
    this.saveLocalUser(user);
    return this.onlineUser.pipe(
      switchMap((userData: Client) => {
        this.onlineUser$.next(userData);
        this.saveLocalUser(userData);
        return this.router.navigate(['/']);
      }),
      untilDestroyed(this)
    );
  }

  async logout (force = false) {
    const scope = SentryAngular.getCurrentScope();
    if (force) {
        console.error('User logged forced to log out')
    }
    this.storage.set('user', null);
    this.storage.set('token', null);
    scope.setUser(null);
    this.onlineUser$.next(undefined);
    this.jwt = undefined;
    return lastValueFrom(this.activatedRoute.queryParams.pipe(take(1))).then((queryParams) => {
      return this.router.navigate(['/auth'], {queryParams})
    });
  }

  private saveLocalUser (userData?: Client) {
    if (userData) {
      const scope = SentryAngular.getCurrentScope();
      scope.setUser({
        id: userData.id,
        email: userData.email,
      });
      this.storage.set('user', JSON.stringify(userData));
    }
  }
}

